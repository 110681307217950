<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <h1>Easter Egg #2</h1>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <p>
          To unlock your 1st task enter the year of the first ever "T3"
          Spikeball Tournament.
        </p>
      </v-col>
    </v-row>

    <h4>Task 1</h4>

    <EggTask :length="4" expected="2021" task="Kan Jam" route="Egg2Task1" />

    <h4>Task 2</h4>

    <EggTask :length="4" expected="KOBE" task="Mamba" route="Egg2Task2" />

    <h4>Task 3</h4>

    <EggTask :length="1" expected="5" task="Strike" route="Egg2Task3" />

    <h4>Prize</h4>

    <EggTask :length="4" expected="GIGI" task="Egg #2" route="Egg2Prize" />
  </v-container>
</template>

<script>
import EggTask from '@/components/EggTask.vue'

export default {
  components: { EggTask },
  data() {
    return {}
  },
}
</script>

<style></style>
